import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActivitiesStore } from './activities.reducer';
import {
  activitiesFeatureKey,
  Activity,
  ACTIVITY_VIEW,
  ActivityViewTypes,
  calculateDifferenceBetweenDates,
  UNASSIGNED_ID,
} from './activities.constants';
import dayjs from 'dayjs';
import { DeepCopyService } from '../../services/deep-copy.service';

export const activitiesFeatureSelector =
  createFeatureSelector<ActivitiesStore>(activitiesFeatureKey);

const addProgressToActivity = (activity: Activity) => {
  const { percent, dayCount } = calculateDifferenceBetweenDates(
    activity.start_date,
    activity.end_date,
    activity.completed,
  );

  activity = DeepCopyService.deepCopy(activity);

  if (activity?.checklist_activities) {
    activity.checklist_activities = activity.checklist_activities.map((checklistActivity) => {
      const { percent, dayCount } = calculateDifferenceBetweenDates(
        checklistActivity.start_date,
        checklistActivity.end_date,
        checklistActivity.completed,
      );
      return {
        percent,
        dayCount,
        ...checklistActivity,
      };
    });
  }

  return {
    percent,
    dayCount,
    ...activity,
  };
};

const isViewEmpty = createSelector(activitiesFeatureSelector, (state) => {
  if (state.selectedView === ACTIVITY_VIEW.ACTIVITIES) {
    if (
      state.isKanbanLoading.overdueProgress ||
      state.isKanbanLoading.openInvoices ||
      state.isKanbanLoading.openContracts
    ) {
      return false;
    }
    return (
      state.overdueProgress.length === 0 &&
      state.openInvoices.length === 0 &&
      state.openContracts.length === 0
    );
  }
  if (state.selectedView === ACTIVITY_VIEW.ACTIVITY_LOG) {
    if (state.isLoadingGeneral) {
      return false;
    }
    return state.activityLogs.length === 0;
  }
  if (state.selectedView === ACTIVITY_VIEW.CALENDAR) {
    // we can't determine if the calendar is empty or not, because months are loaded dynamically
    return false;
  }
  console.warn('dev note: view type not implemented yet');
  return true;
});

const isThereAnyActivity = createSelector(
  activitiesFeatureSelector,
  (state) => state.isThereAnyActivity,
);

const getOverdueProgressActivities = createSelector(activitiesFeatureSelector, (state) =>
  state.overdueProgress?.map(addProgressToActivity),
);

const getOpenInvoicesActivities = createSelector(activitiesFeatureSelector, (state) =>
  state.openInvoices?.map(addProgressToActivity),
);

const getOpenContractsActivities = createSelector(activitiesFeatureSelector, (state) =>
  state.openContracts?.map(addProgressToActivity),
);

const getAllViewActivities = createSelector(activitiesFeatureSelector, (state): Activity[] => {
  return [...state.overdueProgress, ...state.openInvoices, ...state.openContracts].map(
    addProgressToActivity,
  );
});

const getActivitiesLog = createSelector(activitiesFeatureSelector, (state) =>
  state.activityLogs.map(addProgressToActivity),
);

const getAllCalendarActivities = createSelector(activitiesFeatureSelector, (state) =>
  state.calendarActivities.map(addProgressToActivity),
);

const getCalendarActivities = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) =>
  createSelector(activitiesFeatureSelector, (state) =>
    state.calendarActivities
      .filter(
        (activity) =>
          dayjs(activity.start_date).isAfter(startDate) &&
          dayjs(activity.start_date).isBefore(endDate),
      )
      .map(addProgressToActivity),
  );

const getCalendarDateWindow = createSelector(
  activitiesFeatureSelector,
  (state) => state.calendarDateWindow,
);

const getNextPage = (type: ActivityViewTypes) =>
  createSelector(activitiesFeatureSelector, (state) => {
    return state.nextKanbanPage[type];
  });

const getLogPage = (type: ActivityViewTypes) =>
  createSelector(activitiesFeatureSelector, (state) => {
    return state.logPage;
  });

const getNextCalendarPage = createSelector(
  activitiesFeatureSelector,
  (state) => state.nextCalendarPage,
);

const getFilters = createSelector(activitiesFeatureSelector, (state) => state.filters);
const getSelectedAssigneeIds = createSelector(activitiesFeatureSelector, (state) => {
  const assigneeIds: number[] = [];
  if (state.filters['assigneeIds[]']) {
    assigneeIds.push(...state.filters['assigneeIds[]']);
  }
  if (state.filters.unassigned) {
    assigneeIds.push(UNASSIGNED_ID);
  }

  return assigneeIds;
});

const isKanbanLoading = (type: ActivityViewTypes) =>
  createSelector(activitiesFeatureSelector, (state) => state.isKanbanLoading[type]);

const isLoadingGeneral = createSelector(
  activitiesFeatureSelector,
  (state) => state.isLoadingGeneral,
);

const getSelectedView = createSelector(activitiesFeatureSelector, (state) => state.selectedView);

export const activitiesSelector = {
  activitiesFeatureSelector,
  isViewEmpty,
  isThereAnyActivity,
  getOverdueProgressActivities,
  getOpenInvoicesActivities,
  getOpenContractsActivities,
  getActivitiesLog,
  getAllCalendarActivities,
  getCalendarActivities,
  getCalendarDateWindow,
  getNextPage,
  getLogPage,
  getNextCalendarPage,
  getFilters,
  getSelectedAssigneeIds,
  isKanbanLoading,
  isLoadingGeneral,
  getAllViewActivities,
  getSelectedView,
};
